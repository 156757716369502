import { PaginationData, PaginationDefault, request } from '@/util/request';

/**
 * 添加广告
 * @param params
 * @returns
 */
export async function addAdv(params: {
  banner_url: string;
  jump_type: number;
  name: string;
  position: number;
  remark: string;
  status: number;
  title: string;
}) {
  const url = `/boss/operation/adv`;
  try {
    const res = await request.post(url, params);
    if (res.status === 200 && res.data) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
  return false;
}
/**
 * 获取广告列表
 * @param params
 * @returns
 */
export async function getAdvList(params: {
  current?: number;
  limit?: number;
  name?: string;
  status?: string;
}) {
  const url = `/boss/operation/adv`;
  try {
    const res = await request.get<PaginationData<any>>(url, { params });
    if (res.status === 200 && res.data) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
  return PaginationDefault;
}
/**
 * 编辑广告
 * @param params
 * @returns
 */
export async function editAdv(
  id: number,
  params: {
    banner_url: string;
    jump_type: number;
    name: string;
    position: number;
    remark: string;
    status: number;
    title: string;
  }
) {
  const url = `/boss/operation/adv/${id}`;
  try {
    const res = await request.put(url, params);
    if (res.status === 200 && res.data) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
  return false;
}